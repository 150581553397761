@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali:wght@400;500;700&display=swap");

.dark {
  @apply bg-[#222222] text-slate-400;

  .navbar,
  .footer,
  .menu,
  .mobile-navbar {
    @apply bg-[#222222] text-slate-200;
  }

  /* Form fields */
  .card,
  input,
  select,
  textarea,
  button {
    @apply bg-slate-800 text-slate-200 border-slate-600;
  }

  /* Labels */
  label span {
    @apply text-slate-400;
  }

  /* Placeholder text */
  input::placeholder,
  textarea::placeholder {
    @apply text-slate-500;
  }

  /* Borders */
  .dark-border {
    @apply border-slate-600;
  }

  /* Focus states */
  input:focus,
  select:focus,
  textarea:focus {
    @apply border-blue-500 outline-none;
  }

  /* Buttons */
  button {
    @apply bg-slate-600 text-slate-100 hover:bg-blue-500;
  }

  /* Additional styling */
  .dark-card {
    @apply bg-slate-800 border-slate-600 shadow-md;
  }

  .next_batch_reg_card {
    @apply bg-slate-700;
  }
  .next_batch_reg_heading p {
    @apply text-teal-500;
  }
  .next_batch_reg_text p,
  .modal-box,
  th,
  td {
    @apply bg-slate-800 text-slate-400;
  }
  .course_card,
  .level_info_card,
  .header-card,
  .payment-card {
    @apply from-slate-800 to-slate-600;
  }
  .noticeboard_card,
  .schedule_card,
  .registration_info_card div,
  .learning_method_card,
  .skeleton {
    @apply bg-slate-800;
  }
  .schedule_card th,
  .menu span,
  table th {
    @apply text-slate-400;
  }

  .quote_sign {
    @apply text-slate-500 z-0;
  }
  .quote_text {
    @apply text-yellow-600;
  }
  .quote_texts {
    @apply z-10;
  }
  .gift-box {
    @apply bg-orange-950 text-yellow-600;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Noto Serif Bengali", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
